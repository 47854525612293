.calendar-container
{
    display: grid;
    grid-template-rows: 26px 130px ;
    grid-template-areas: "calendar-header"
    "calendar-dates";
}

.calendar-header
{
    grid-area: calendar-header;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px; 
}
.header-current-month{
    color:#bcbebf;
    text-transform: uppercase;
    font-size: 16px;
}
.calendar-dates{
    grid-area: calendar-dates;
    display: grid;
    grid-template-rows: repeat(7,21.667px);
    grid-template-columns: repeat(7,32.714px);
    justify-content: center;

}
.day
{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:#4c4b4d;
}
.day:hover{
    background-color:#f49422;
    color: white;
}
.today
{
    border: 1px solid #f49422;
}
.d_h
{
    font-weight: 450;
    color : #f49422;
    cursor:unset;
}
.d_h:hover
{
    font-weight: 450;
    color : #f49422;
    background-color: transparent;
}
.date-selected
{
    background-color:#f49422;
    color: white;
}

.header-arrow
{
    font-size: 25px;
    cursor: pointer;
    color:#bcbebf;

}
.disabled-date
{
    color:#d4d4d4;
    cursor:auto;
}
.disabled-date:hover{
    background-color: #FFF;
    color:#d4d4d4;
}
.blank{
    cursor:unset;
}
.blank:hover{
    background-color: #FFF;
    color:#d4d4d4;
}
.pickupDate
{
    background-color: #bcbebf;
    color:#FFF;
}
.betweenDate{
    background-color:#f6f6f6;
    color: #4c4b4d;
}

