@media only screen and (max-width: 1169px) {
    .dropdown{
     width: 100%;
     margin-top:20px;
    }   
 }
.dropdown
{
    width: 245px;
    height:30px;
    color:#414042;
    font-size: 16px;
    font-family: "Yantramanav";
    border-width: 1px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 10px;
    cursor: pointer;
}

.dropdown-inactive{
    color:#bcbdbf;
    border-width: 1px;
    font-family: "Yantramanav";
    border-color: rgb(224, 224, 224);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}
.dropdown-error-message
{
    font-size: 12px;
    font-family: "Yantramanav";
    color: rgb(255, 0, 0);
    line-height: 5;
}
.dropdown-error
{
    border-width: 2px;
    border-color: rgb(255, 0, 0);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    color:rgb(255, 0, 0);
}
.dropdown-error-only-border{
    border-width: 2px;
    border-color: rgb(255, 0, 0);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}
.dropdown-active{
    font-size: 16px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
}

.dropdown-list
{
    height:auto;
    max-height:180px;
    width:245px;
    background-color: #FFF;
    position: absolute;
    z-index: 100;
    overflow-x: hidden;
    font-size: 16px;
    font-family: "Yantramanav";
    color:#bcbdbf;
    border-width: 1px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
    border-top: none;
    border-radius: 0 0 4px 4px;
    scrollbar-width: none;
}
.dropdown-list-datepicker{
    overflow: hidden;
}

.dropdown-visible{
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}
.dropdown-hidden{
    display: none;
}
.dropdown-datepicker
{
    height: 188px;
    width:245px;
    padding: 7px;
    box-sizing: border-box;
    background-color: #FFF;
    position: absolute;
    margin-top:103px;
    margin-left:-11px;
    z-index: 100;
    font-size: 16px;
    color:#bcbdbf;
    border-width: 1px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
    border-top: none;
    border-radius: 0 0 4px 4px;
}
.dropdown-options
{
    padding: 0;
}
.dropdown-option{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    cursor: pointer;
}
.dropdown-option:hover
{
    background-color:#f49422;
    color:#FFF;
    border-radius: 4px;
}
::-webkit-scrollbar { 
    display: none; 
}




  
