::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:#bcbdbf;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#bcbdbf;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:#bcbdbf;
  }
@media only screen and (max-width : 1169px)
{
    .email-form
    {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .text-input{
        margin-top: 20px;
        height: 30px;
        width: 243px;
    }
    .text-input-area{
        height:200px;
        width: 243px;
    }
    .email-form-bottom
    {
        margin-top:15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .email-form-bottom-message{
        max-width: 230px;
        margin: auto;
        padding-bottom: 15px;
        text-align: center;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(188, 189, 191);
    }
}
@media only screen and (min-width : 1169px)
{
    .email-form-row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .text-input{
        height: 30px;
        width: 243px;
    }
    .text-input-area{
        height:150px;
        width:100%;
    }
    .email-form-bottom
    {
        margin-top:20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .email-form-bottom-message{
        width: 390px;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(188, 189, 191);
    }
      
}

.text-input{
    outline:none; 
    font-size: 16px;
    font-family: "Yantramanav";
    border-width: 1px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    padding: 05px;
    display: flex;
    align-items: center;
}
.text-input:focus
{
    outline:none; 
}
.text-input-active
{
    color: rgb(65, 64, 66);
}

.text-input-error{    
    border-width: 2px;
    border-color: rgb(255, 0, 0);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    color:rgb(255, 0, 0);
}
.error-label{
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;
    font-size: 12px;
    font-family: "Yantramanav";
    color: rgb(255, 0, 0);
}
.hidden{
    display: none;
}



  

  