.modal-footer{
    display: flex;
    justify-content: center;
}
.modal-body{
    padding-top: 70px;
}
.loading-modal{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-message{
    margin-bottom: 20px;
}
.modal-phone{
    color:#b55a13;
}
.modal-phone-call{
    font-size:1em;
    margin-top:40px;
    margin-bottom: 20px;
}