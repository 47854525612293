.get-a-quote-wrap{
    width : 100%;
    margin-top: 0px;
}
@media only screen and (max-width : 1169px)
{
    .gaq{
        margin: auto;
        padding:50px 0;
        width: 320px;
    }
    .gaq-car-data
    {
        height: 380px;
        width: 320px;
        text-align: center;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
    
    }
    .gaq-car-name
    {
        font-weight: bold;
    }
    .gaq-car-extras
    {
        margin-top: 16px;
        color: rgb(188, 189, 191);
    }
    .gaq-email-form
    {
        width: 320px;
    }
    
}
@media only screen and (min-width : 1170px)
{
    .gaq{
        max-width: 1170px;
        margin: auto;
        padding:50px 15px;
        display: flex;
        justify-content: space-around;
    }
    .gaq-car-data
    {
        height: 380px;
        width: 320px;
        text-align: center;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
    
    }
    .gaq-car-name
    {
        font-weight: bold;
    }
    .gaq-car-extras
    {
        margin-top: 16px;
        color: rgb(188, 189, 191);
    }
    .gaq-email-form
    {
        width: 782px;
        height: 380px;
    }
        
}
.secondary-light:hover{
    color:black;
}
