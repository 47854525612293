.extra-services-wrap
{
    width:100%;
    background-color: #FFF;
}
@media only screen and (max-width : 1169px)
{
    .extra-services
    {
        max-width: 305px;
        padding: 50px 0px;
        margin:auto;
        display: flex;
        flex-direction: column;
    }
    .extra-services-text
    {
        width:100%;
        font-family: "Ubuntu";
        font-size: 22px;
        line-height: 26px;
        font-family: "Ubuntu";
        color: rgb(188, 189, 191);
        font-weight: bold;
    }
    .extra-services-services{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap : wrap;
    }
    .extra-services-service
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        flex: 1 0 50%;
        margin-top: 30px;
    }
    .extra-service-title{
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (min-width : 1170px)
{
    .extra-services
    {
        max-width: 1170px;
        padding: 70px 15px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 50px;
    }
    .extra-services-text
    {
        width:270px;
        font-family: "Ubuntu";
        font-size: 26px;
        line-height: 30px;
        font-family: "Ubuntu";
        color: rgb(188, 189, 191);
        font-weight: bold;
    }
    .extra-services-services{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap : wrap;
    }
    .extra-services-service
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        flex:1 0 25%;
    }
}
.extra-services-text-light
{
    color:#f39422;
}
.extra-service-title
{
    font-size: 14px;
    font-family: "Ubuntu";
    color: rgb(188, 189, 191);
    font-weight: bold;
    text-align: center;
}

.extra-service-icon{
    font-size : 30px;
    color:#f39422;
}

