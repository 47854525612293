.footer-wrap
{
    width: 100%;
    background-color: #000;
}
@media only screen and (max-width : 1169px)
{
    .footer
    {
        max-width: 305px;
        padding: 35px 0px;
        margin:auto;
        color:#FFF;
        font-size: 14px;
        font-family: "Ubuntu";
    }
    .footer-section1-companyName
    {
        padding-right: 10px;
    }
    .footer-section1-link{
        color:#f39422;
        cursor: pointer;
        display: block;
        line-height:32px;
    }
    .first-link{
        margin-left: 0px;
        display: inline-block;
    }
    .footer-section2{
        font-size : 14px;
        font-family : "Ubuntu";
        color:#a3a4a6;
        margin-top: 16px;
        line-height: 26px;
    }
    .footer-section2-element{
        display: block;
    }
    .footer-section2-icon
    {
        padding-right: 8px;
        font-size:16px;
    }

}
@media only screen and (min-width : 1170px)
{
    .footer{
        max-width : 1170px;
        padding: 30px 15px;
        margin: auto;
        color:white;
        display :flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: "Ubuntu";
    }
    .footer-section1-companyName
    {
        padding-right: 10px;
    }
    .footer-section1-link{
        margin-left:30px;
        color:#f39422;
        cursor: pointer;
    }
    .first-link{
        margin-left: 0px;
    }
    .footer-section2{
        font-size : 14px;
        font-family : "Ubuntu";
        color:#a3a4a6;
    }
    .footer-section2-element{
        padding-left:30px; 
    }
    .footer-section2-icon
    {
        padding-right: 8px;
        font-size:16px;
    }
}
.footer-link{
    color : #f39422;
    font-size : 14px;
}
.footer-mail{
    font-size : 14px;
    font-family : "Ubuntu";
    color:#a3a4a6;
    margin-top: 16px;
    line-height: 26px;
}