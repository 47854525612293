@media only screen and (max-width:1169px) {
    .fleet-wrap
    {
        padding-top:60px;
        width: 100%;
        background-color: #f1f2f2;
    }
    .filter-options{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .filter-option{
        display: flex;
        justify-content: center;
    }
    .distancer-hidden{
        display: none;
    }
    .cars-container
    {
    margin: auto;
    width:270px;
    padding-bottom: 30px;
    }
    
 }
 @media only screen and (min-width: 1170px) {
    .fleet-wrap
    {
        padding-top:25px;
        width: 100%;
        background-color: #f1f2f2;
    }
    .cars-container
    {
    max-width: 880px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 270px);
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    padding-bottom: 40px;
    }
 }

.fleet-filter-wrap
{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    padding-bottom: 40px;
    
}
.filter-options{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style-type: none;
    font-size: 16px;
    color: #bcbec0;
}

.option{
    cursor: pointer;
}
.selected{
    color:#f39422;
    font-weight: bold;
}
.distancer{
    padding: 0 10px;
}
.opt0-0, .opt1-1, .opt2-2, .opt3-3, .opt4-4, .opt5-5 {
    color:#f39422;
    font-weight: bold;
}
.fleet
{
    max-width: 1170px;
    margin: auto;
    height: auto;
    
}





