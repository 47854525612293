@media only screen and (max-width:1169px) {
    .car-wrap{
        margin-bottom:20px;
    }
}
@media only screen and (min-width:1170px) {
  .car-wrap:hover
  {
    transform: scale(1.05);
    cursor: pointer;
  }

}
.car-wrap
{
    background-color: #FFF;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 370px;
    padding:15px;
}
.car-name
{
    font-size: 20px;
    font-family: "Ubuntu";
    color: rgb(243, 148, 34);
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    display: block;
}
.car-type{
    font-size: 18px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    display: block;
}
.car-transmission{
    margin-top:16px;
}
.car-transmission, .car-person,.car-ac{
    font-size: 18px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
    line-height: 22px;
    text-align: center;
    display: block;
}
.car-ac
{
    margin-bottom: 16px;
}