.location-component{
    padding: 0 0 15px 0;
}
.location-component-header{
    height: 60px;
    box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.location-header-heading
{
    font-size: 22px;
    font-family: "Ubuntu";
    color: rgb(243, 148, 34);
    font-weight: bold;
}
.location-header-price{
    color: rgb(65, 64, 66);
    font-size: 18px;
    font-family: "Ubuntu";
    font-weight: bold;
    margin-left: 10px;
}
.location-component-body{
    padding: 10px 15px;
    font-size: 14px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
}
@media only screen and (max-width : 1169px)
{
    .display-none{
        display: none;
    }
    .location-header-price-invisible{
        display: none;
    }
    .location-component-header{
        cursor: pointer;
    }
    .location-header-heading
    {
        display: inline-block;
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .location-header-heading-full{
        font-size: 22px;
        font-family: "Ubuntu";
        color: rgb(243, 148, 34);
        font-weight: bold;
        padding-right: 10px; 
    }
    
}
@media only screen and (min-width : 1170px)
{
    .location-component{
        padding: 0px 15px 15px 15px;
    }
    .display-none{
        padding: 10px 15px;
        font-size: 14px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
    }
    .location-header-price-invisible{
        color: rgb(65, 64, 66);
        font-size: 18px;
        font-family: "Ubuntu";
        font-weight: bold;
        margin-left: 10px;
    }
    .location-header-heading-full{
        font-size: 22px;
        font-family: "Ubuntu";
        color: rgb(243, 148, 34);
        font-weight: bold;
    }
}