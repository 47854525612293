@media only screen and (max-width:1169px){
    .header-wrap
    {
        background: url("../../images/Mobile/header_image_mobile.jpg");
        height: 250px;
        width: 100%;
        margin-top:50px;
    }
    .header{
        font-family:"Ubuntu";
        height: 100%;
        padding: 0 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction:column;
    }
    .header-primary
    {
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
    }
    .header-secondary
    {
        margin-top:16px;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
    }
}
@media only screen and (min-width:1170px){

    .header-wrap
    {
        background: url("../../images/Desktop/header_image_desktop.jpg");
        height: 250px;
        width: 100%;
        margin-top:50px;
    }
    .header{
        font-family:"Ubuntu";
        max-width: 1170px;
        height: 100%;
        padding: 0 15px;
        margin: auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction:column;
    }
    .header-primary
    {
        font-weight: bold;
        font-size: 42px;
        line-height: 42px;
    }
    .header-secondary
    {
        margin-top:24px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }
}
.primary-light
{
    color: #FFF;
}
.secondary-light
{
    color: #FFF;
}
.secondary-dark{
    color:#b55a13;
}