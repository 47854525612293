@media only screen and (max-width: 1169px) {
    .desktop-reservation-bar{
     position: relative;
     width:290px;
     padding-top: 4px;
     margin: auto;
     bottom: -30px;
     height:270px;
     display: flex;
     flex-direction: column;
     align-items: center;
    }
    .button-wrap
    {
        margin-top: 20px;
    }   
 }
@media only screen and (min-width: 1170px) {
   .desktop-reservation-bar{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
   }   
}

.desktop-reservation-bar
{
    border-radius: 10px;
    background-color: #f6f6f6;
    box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.15);
}