.locations-wrap{
    width: 100%;
}

@media only screen and (max-width : 1169px){

    .list-of-locations{
        width:100%;
    }
    
}
@media only screen and (min-width : 1170px){

    .list-of-locations{
        max-width: 1170px;
        margin: auto;
        padding: 15px 0;
    }
}

