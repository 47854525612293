.ts{
    max-width: 1170px;
    padding: 50px 15px;
    margin: auto;
}
.ts-heading
{
    font-size: 22px;
    font-family: "Ubuntu";
    color: rgb(243, 148, 34);
    font-weight: bold;
    padding-bottom: 25px;
    padding-top: 35px;
}
.ts-text{
    font-size: 16px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
}
.ts-list-el{
    padding-left: 20px;
}
  