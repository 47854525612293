.contact-wrap{
    width: 100%;
}
@media only screen and (max-width : 1169px)
{
    .contact{
        margin: auto;
        padding:30px 15px;
        display: flex;
    }
    .about-us{
        text-align: left;
    }
    .contact-heading{
        font-size: 22px;
        font-family: "Ubuntu";
        color: rgb(243, 148, 34);
        font-weight: bold;
        display: block;
        margin-bottom: 20px;
    }
    .about-us-text{
        font-size: 14px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
        line-height: 14px;
    }
    .contact-section{
       margin-top:20px;
    }

    .contact-info
    {
        text-align: left;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
        padding-bottom: 20px;
    }
    .contact-text-row
    {
        display: block;
    }
    .contact-phone
    {
        margin-top:20px;
    }
   
    
}
@media only screen and (min-width : 1170px)
{
    .contact{
        max-width: 1170px;
        margin: auto;
        padding:50px 15px;
        display: flex;
        justify-content: space-around;
        min-height: 700px;
    }
    .about-us{
        text-align: left;
    }
    .contact-heading{
        font-size: 22px;
        font-family: "Ubuntu";
        color: rgb(243, 148, 34);
        font-weight: bold;
        display: block;
        margin-bottom: 20px;
    }
    .about-us-text{
        font-size: 14px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
        line-height: 14px;
    }
    .contact-section{
       margin-top:40px;
       display: flex;
       justify-content: space-between; 
    }
    .contact-info
    {
        height: 380px;
        width: 320px;
        text-align: left;
        font-size: 16px;
        font-family: "Yantramanav";
        color: rgb(65, 64, 66);
    }
    .contact-text-row
    {
        display: block;
    }
    .contact-phone
    {
        margin-top:20px;
    }
    .gaq-car-extras
    {
        margin-top: 16px;
        color: rgb(188, 189, 191);
    }
    .contact-form
    {
        width: 782px;
    }
        
}