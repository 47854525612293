@media only screen and (min-width: 1170px)
{
    .nav-link:hover{
        color: rgb(243, 148, 34);
    }
}
.nav-wrap
{
    height: 50px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    display: block;
    transition: top 0.3s;
    z-index: 1000;
}
.nav-hidden{
    top:-50px;
}
.nav-bar
{
    max-width: 1170px;
    height: 50px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-links{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: rgb(65, 64, 66);
}
.nav-link{
    padding:0;
    margin-left:30px;
    cursor: pointer;
}
.menu-mobile
{
    background-color: rgb(255,255,255);
    position: absolute;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.15);
    padding-top: 10px;
    padding-bottom: 0;
}
.mobile-nav-links{
   display: flex;
   flex-direction: column;
   color: rgb(65, 64, 66);
   padding-left: 20px;
}
.mobile-nav-link{
    display: flex;
}
.mobile-nav-link a{
    color: rgb(65, 64, 66);
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    text-decoration: none;
}
.last-menu-option
{
    margin-bottom: 0px;
}
a{
    color: rgb(65, 64, 66);
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
a:hover{
    text-decoration: none;
    color: rgb(243, 148, 34);
}
.active-link{
    color: rgb(243, 148, 34);
}

