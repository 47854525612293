.pp{
    max-width: 1170px;
    padding: 50px 15px;
    margin: auto;
}
.pp-heading
{
    font-size: 22px;
    font-family: "Ubuntu";
    color: rgb(243, 148, 34);
    font-weight: bold;
    padding-bottom: 25px;
    padding-top: 35px;
}
.pp-text{
    font-size: 16px;
    font-family: "Yantramanav";
    color: rgb(65, 64, 66);
}
.pp-list-el{
    padding-left: 20px;
}
.pp-mail{
    color:rgb(243, 148, 34);
}