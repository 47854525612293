.button
{
    background-color: rgb(243, 148, 34);
    font-size: 16px;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 20px;
    border-radius: 24px;
    height: 30px;
    cursor: pointer;
}
.button:hover
{
        background-color: rgb(162, 80, 23);
}


 
  