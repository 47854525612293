body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
.Ubuntu
{
  font-family: 'Ubuntu', sans-serif;
}
.Yantramanav
{
  font-family: 'Yantramanav', sans-serif;
}
html {
  scroll-behavior: smooth;
}
