@media only screen and (min-width: 50px) {
    .home-header
    {
        background: url("../../images/Mobile/home_image_mobile.jpg") top ;
        height: 425px;
        width: 100%;
    }
    .home-header-primary
    {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }
    .home-header-secondary
    {
        margin-top:25px;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
    }
    .home-header-content
    {
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 339px) {
    .home-header
    {
        background: url("../../images/Mobile/home_image_mobile.jpg") top ;
        height: 425px;
        width: 100%;
    }
    .home-header-primary
    {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }
    .home-header-secondary
    {
        margin-top:18px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
    }
    .home-header-content
    {
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    

}

@media only screen and (min-width:340px) {
    .home-header
    {
        background: url("../../images/Mobile/home_image_mobile.jpg") top ;
        height: 425px;
        width: 100%;
    }
    .home-header-primary
    {
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
    }
    .home-header-secondary
    {
        margin-top:20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
    }
    .home-header-content
    {
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
 }
 @media only screen and (min-width: 1170px) {
    .home-header
    {
        background: url("../../images/Desktop/home_image.jpg") top ;
        height: 550px;
        width: 100%;
    }
    .home-header-primary
    {
        font-weight: bold;
        font-size: 42px;
        line-height: 42px;
    }
    .home-header-secondary
    {
        margin-top:26px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }
    .home-header-content
    {
        margin: auto;
        height: 100%;
        max-width: 1170px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 100px;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
 }
.primary-light
{
    color: #FFF;
}
.primary-dark
{
    color:#a14f0c;
}
.secondary-dark{
    color:#b55a13;
}
.home-header{
    margin-top: 50px;
}

